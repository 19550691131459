/* General Body Styling */
body {
  margin: 0;
  background-color: rgb(35, 39, 42);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
}

ul {
  padding: 0;
}

/* App Container */
.app-container {
  display: inline-flex;
  height: 100vh;
  background-color: #191919;
  color: #fff;
  position: relative;
  width: 100vw;
}


input[type=checkbox]:checked:before {
  color: #fff;
  content: "\2713";
  font-size: 16px;
  left: 11px;
  position: absolute;
  top: 4px;
  text-align: center;
}


/* Styling for checkboxes */

input[type="checkbox"] {
    appearance: none;
    width: 24px;
    height: 24px;
    background-color: #2a2a2a;
    border: 2px solid #555;
    border-radius: 4px;
    position: relative;
    max-width: 37px;
    cursor: pointer;
    transition: all 0.3s ease;
    min-height: 35px;
    min-width: 37px;
}

input[type="checkbox"]:checked {
  background-color: #b50c0c;
  border-color: #ba1111;
}



input[type="checkbox"]:hover {
  background-color: #3b3b3b;
  border-color: #777;
}

input[type="checkbox"]:checked:hover {
  background-color: #ba1111;
  border-color: #ba1111;
}

/* Label and container styling */
label {
  
  font-size: 18px;
  margin-left: 8px;
  vertical-align: 15px;
  cursor: pointer;
}

.chapter-item {
  display: flex;
  align-items: center;
  padding: 8px 0;
}

.chapter-item .chapter-item span {
  margin-left: 12px;
}


#connection {
  position: absolute;
  top: 10px;
  background: #171717;
  padding: 20px;
  /* color: white; */
  color: white;
  font-size: 20px;
  border-radius: 10px;
  font-weight: bold;
}


/* Panels */
.left-panel, .right-panel, .main-panel {
  padding: 20px;
  position: relative;
}

.left-panel {
  background-color: #141414;
  overflow-y: auto;
  width: 15%;
  max-width: 210px;
  padding: 0;
  text-align: center;
}

.right-panel {
  width: 20%;
  padding-left: 40px;
  overflow-y: auto;
  max-width: 300px;
}

.main-panel {
  flex-grow: 1;
  padding: 0 20px;
  overflow-y: auto;
}


.footer p {
  margin: 0;
}

.footer ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  gap: 10px;
  font-size: 14px;
}

.footer-link {
  color: inherit;
  text-decoration: none;
}

/* Scrollbar Styling */
::-webkit-scrollbar {
  width: 12px;
  background-color: #2c2c2c;
}

::-webkit-scrollbar-thumb {
  background-color: #444;
  border-radius: 6px;
  border: 3px solid #2c2c2c;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #666;
}


/* Buttons */
button, .button {
  padding: 15px 10px;
  border-radius: 10px;
  background-color: transparent;
  border: 1px solid #ba1111;
  color: #fff;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: bold;
  margin: 10px;
  transition: background-color 0.3s, color 0.3s;
}

/* Hover state */
button:hover, .button:hover {
  background-color: #ba1111;
  color: #fff;
}

/* Active state */
button:active, .button:active {
  background-color: #ba1111; /* Darker shade of the border color */
  color: #fff;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3); /* Adds a subtle inset shadow */
}

/* Focus state */
button:focus, .button:focus {
  outline: none;
  border-color: #a69ce1; /* Lighter shade of the border color */
  box-shadow: 0 0 3px 2px rgba(163, 148, 239, 0.5); /* Adds a glow effect */
}


.button.save-button {
  background-color: #28a745;
}

input.metadata-input {
  width: 90%;
  margin: 10px;
}

.metadata-array-item {
  border: 1px solid rgb(128, 0, 0);
  padding: 10px;
  margin: 10px;
}

details.metadata-group {
  border: 1px solid #888;
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
}

summary.metadata-summary {
  border: 1px solid gray;
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;
}

.metadata-container {
  border: 1px solid #bc6464;
  padding: 20px;
  margin: 20px;
  background-color: #1c1c1c;
  color: #fff;
  transition: all 0.3s ease;
}
.tools {
  background: #151515;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #bc6464;
  margin-top: 80px;
}
.metadata-container[open] {
  max-height: 1000vh;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
}

.metadata-container legend {
  font-size: 1.5em;
  font-weight: bold;
  padding: 0 10px;
  cursor: pointer;
}

.metadata-container details {
  margin-top: 10px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  background: #2c2c2c;
}
fieldset.metadata-container {
  max-height: 390px;
  margin: 0;
  overflow: hidden;
}

.metadata-container summary {
  font-weight: bold;
  cursor: pointer;
}

.metadata-container details[open] {
  padding-bottom: 15px;
}

.metadata-container details[open] summary::after {
  content: "▼";
  margin-left: 5px;
}

.metadata-container details summary::after {
  content: "►";
  margin-left: 5px;
}

summary.metadata-summary:hover {
  background-color: #7364bc;
}

.button.summarize-button {
  background-color: #ffc107;
  color: black;
}

.button.copy-button,
.button.clear-button {
  margin-top: 10px;
}

.button.process-queue-button {
  margin-top: 20px;
  background-color: #17a2b8;
}

.delete-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 1.2em;
  color: red;
}

.delete-button:hover {
  color: darkred;
}

/* Inputs */
.main-panel input, .input-box, .metadata-input, .summarize-textarea, textarea.selected-chapter {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: rgb(64, 68, 75);
  color: rgb(255, 255, 255);
}
.send-container button {
  margin: 10px 0;
}
.metadata-input {
  margin-left: 10px;
}

.send-container input {
  max-width: calc(100% - 20px);
}
.send-container {
  display: inline-flex;
  flex-direction: column;
  width: 100%;
}
.summarize-textarea {
  height: 100px;
  margin: 10px 0;
}

textarea.selected-chapter {
  margin-top: 10px;
  padding: 20px;
  border-radius: 5px;
  overflow-y: auto;
  min-height: 50vh;
  max-width: calc(100% - 40px);
}

/* Status Messages */
.status-message {
  display: inline-block;
  margin-top: 10px;
}

.spinner {
  color: yellow;
}

.status-done {
  color: #4caf50;
}

.status-error {
  color: #f44336;
}

.connection-status {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 10;
}
button.process-dashboard-btn {
  font-size: 20px;
  background: #7364bc;
  color: white;
  border: 10px solid white;
}
.connection-status.connected {
  color: green;
}

.connection-status.disconnected {
  color: red;
}

/* File Navigation */
.chapter-navigation {
  display: flex;
  align-items: center;
  position: relative;
}

.chapter-navigation h3 {
  margin: 0;
  text-align: center;
  color: #fff;
}

.previous-button, .next-button {
  position: absolute;
  top: 0;
  padding: 5px 10px;
  margin: 10px;
}

.previous-button {
  left: 0;
}

.next-button {
  right: 0;
}

progress {
  width: 100%;
  height: 60px;
  border-radius: 10px;
}

/* Summarization Progress */
.summarization-progress {
  padding: 20px;
  background: #2c2f33;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 5vh;
  left: 10vw;
  width: 80vw;
  height: 75vh;
  border: 10px solid #ad0d0d;
  overflow-y: auto;
  z-index: 1000;
}

.summarization-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.summarization-table th, .summarization-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.summarization-table th {
  background-color: #1d1d1d;
  text-align: left;
}

.progress-bar {
  width: 100%;
  background-color: #f3f3f3;
  border-radius: 5px;
  overflow: hidden;
}

.progress-bar-stripes {
  width: 100%;
  height: 4px;
  background: repeating-linear-gradient(-45deg, #4caf50, #4caf50 10px, #81c784 10px, #81c784 20px);
}

.progress-log {
  max-height: 150px;
  overflow-y: auto;
  background-color: #444;
  padding: 10px;
  border-radius: 5px;
}

/* Miscellaneous */
.metadata-item {
  padding-left: 10px;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background-color: black;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
  width: 50vw;
  text-align: center;
  max-height: 50vh;
  overflow-y: scroll;
  border: 10px solid gray;
  text-align: left;
  border-radius: 10px;
}

.popup-content h2 {
  margin-bottom: 10px;
}

.popup-content p


.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 20px;
  padding: 14px 20px;
}

pre {
  white-space: normal;
}
.mobile-only {
  display: none;
}

h1 {
  text-align: center;
}

.read-mode-button {
  position: absolute;
  right: 0;
  z-index: 10;
  top: 0;
}
.chapter-item span:hover {
  background: #c30000;
  color: #ffffff;
}
.chapter-item span {
  border: 1px solid #111;
  border-radius: 4px;
  padding: 7px 4px;
}
/* Media Queries */
@media (max-width: 800px) {
  .header h1 {
    margin-top: 50px;
}
  textarea.selected-chapter.read-mode {
    position: absolute;
    left: 0;
    top: 0;
    height: 80vh;
  }
  .mobile-only {
    display: inherit;
  }
  .desktop-only {
    display: none;
  }
  .left-panel, .main-panel, .right-panel, .send-container {
    display: none !important;
  }

  .chapter-navigation {
    flex-direction: column;
    align-items: normal;
  }

  .main-panel {
    display: block !important;
    
    text-align: center;
    height: 100vh;
    padding: 0;
    position: relative;
  }

  .read-mode-toggle {
    display: none;
  }

  .footer {
    display: none;
  }
}

.tools {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.tools button {
  width: 100%;
  margin: 10px 0;
}

strong {
  text-transform: uppercase;
}
.metadata-item {
  padding-left: 10px;
  display: inline-flex;
  flex-direction: column;
}
textarea {
  width: calc(100% - 20px);
}
textarea,
 input {
  background-color: #40444b;
  border: none;
  border-radius: 5px;
  color: #fff;
  flex-grow: 1;
  margin-right: 10px;
  padding: 10px;
}

li.chapter-item {
  background: #1f1f1f;
  border-radius: 10px;
  padding: 10px 20px;
  margin: 10px;
  list-style: none;
  cursor: pointer;
}
.chapter-item:hover,
.chapter-item.active {
  background-color: #bc6464; /* or any color you prefer */

}

.chapter-item.active {

  font-weight: bold;
}


details {
  font-size: 17px;
  line-height: 50px;
  margin-left: 20px;
}

details details details {
  background: #bc6464;
  margin: 10px 40px 0 25px;
  padding: 10px;
}